/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import useStyles from './style';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Header from 'components/header';
import Footer from 'components/footerUser';
import { Box, Flex, Text } from '@chakra-ui/react';
import ProfilingComplete from '../../assets/img/ProfilingComplete.webp';
import ProfilingStandard from '../../assets/img/ProfilingStandard.webp';
import ProfilingPilihanCareerTerbaik from '../../assets/img/ProfilingPilihanCareerTerbaik.webp';
import ProfilingSifatdanSikapDitempatKerja from '../../assets/img/ProfilingSifatdanSikapDitempatKerja.webp';
import ProfilingHubunganCintadanSosial from '../../assets/img/ProfilingHubunganCintadanSosial.webp';
import MatchingFriendship from '../../assets/img/MatchingFriendship.webp';
import MatchingPartnership from '../../assets/img/MatchingPartnership.webp';
import MatchingRomance from '../../assets/img/MatchingRomance.webp';
import PersonaGeneral from '../../assets/img/PersonaGeneral.webp';
import PersonaDaily from '../../assets/img/PersonaDaily.webp';

const Service = () => {
  const styles = useStyles();

  return (
    <div className={styles.app}>
      <Header active="home" />
      <div className={styles.heroContainer}>
        <Flex maxWidth={1080} width="100%" direction="column" gap={8}>
          <Box width="100%">
            <Text as="h1" fontWeight="bold" fontSize="lg" mb={3}>PROFILING SERVICE</Text>
            <Flex direction="row" mt={5} overflowX="hidden" width="100%">
              <Flex container gap={5} width="100%" flexWrap="nowrap" overflow="auto" style={{ scrollbarWidth: 'none' }}>
                <Flex direction="column" minWidth={{ sm: '100%', md: 270 }}>
                  <img src={ProfilingPilihanCareerTerbaik} alt="profile" className={styles.img} />
                  <Flex backgroundColor="#016A70" borderTopRadius={10} mb={2} p={2} h={70} justifyContent="center" alignItems="center">
                    <Text color="#fff" fontWeight="bold" textAlign="center">PILIHAN KARIR TERBAIK</Text>
                  </Flex>
                  <Flex position="relative" flexDirection="column" cursor="pointer" onClick={() => window.location.assign('/profiling#0')} borderBottomRadius={10} py={2} px={3} backgroundColor="#059212" h={280}>
                    <Text color="#fff" >Analisa karier ini menggunakan data input berupa tanggal dan jam lahir, kemudian diolah dengan algoritma Persona tertentu untuk menghasilkan berbagai kode sebagai variable input analisis</Text>
                    <Text position="absolute" bottom={5} right={6} color="#fff" textAlign="end">...Read more</Text>
                  </Flex>
                </Flex>
                <Flex direction="column" minWidth={{ sm: '100%', md: 270 }}>
                  <img src={ProfilingSifatdanSikapDitempatKerja} alt="profile" className={styles.img} />
                  <Flex backgroundColor="#016A70" borderTopRadius={10} mb={2} p={2} h={70} justifyContent="center" alignItems="center">
                    <Text color="#fff" fontWeight="bold" textAlign="center">SIFAT DAN SIKAP DITEMPAT KERJA</Text>
                  </Flex>
                  <Flex position="relative" flexDirection="column" cursor="pointer" onClick={() => window.location.assign('/profiling#1')} borderBottomRadius={10} py={2} px={3} backgroundColor="#059212" h={280}>
                    <Text color="#fff" >Dengan menggunakan informasi profiling ini, kami bantu identifikasi sifat dan sikap kerja seseorang, sehingga dapat digunakan untuk memprediksi dan meningkatkan dinamika kerja perusahaan.</Text>
                    <Text position="absolute" bottom={5} right={6} color="#fff" textAlign="end">...Read more</Text>
                  </Flex>
                </Flex>
                <Flex direction="column" minWidth={{ sm: '100%', md: 270 }}>
                  <img src={ProfilingHubunganCintadanSosial} alt="profile" className={styles.img} />
                  <Flex backgroundColor="#016A70" borderTopRadius={10} mb={2} p={2} h={70} justifyContent="center" alignItems="center">
                    <Text as="h1" color="#fff" fontWeight="bold" textAlign="center">HUBUNGAN CINTA DAN SOSIAL</Text>
                  </Flex>
                  <Flex position="relative" flexDirection="column" cursor="pointer" onClick={() => window.location.assign('/profiling#2')} borderBottomRadius={10} py={2} px={3} backgroundColor="#059212" h={280}>
                    <Text color="#fff" >Metode profiling Persona Talenta ini memberikan wawasan tentang dinamika hubungan cinta dan sosial melalui analisis tanggal dan waktu lahir Anda</Text>
                    <Text position="absolute" bottom={5} right={6} color="#fff" textAlign="end">...Read more</Text>
                  </Flex>
                </Flex>
                <Flex direction="column" minWidth={{ sm: '100%', md: 270 }}>
                  <img src={ProfilingStandard} alt="profile" className={styles.img} />
                  <Flex backgroundColor="#016A70" borderTopRadius={10} mb={2} p={2} h={70} justifyContent="center" alignItems="center">
                    <Text as="h1" color="#fff" fontWeight="bold" textAlign="center">PROFILLING ANALITIK STANDARD</Text>
                  </Flex>
                  <Flex position="relative" flexDirection="column" cursor="pointer" onClick={() => window.location.assign('/profiling#3')} borderBottomRadius={10} py={2} px={3} backgroundColor="#059212" h={280}>
                    <Text color="#fff" >Profiling yang kami lakukan secara komputasi otomatis ini dengan output hasil sebagai berikut</Text>
                    <Text position="absolute" bottom={5} right={6} color="#fff" textAlign="end">...Read more</Text>
                  </Flex>
                </Flex>
                <Flex direction="column" minWidth={{ sm: '100%', md: 270 }}>
                  <img src={ProfilingComplete} alt="profile" className={styles.img} />
                  <Flex backgroundColor="#016A70" borderTopRadius={10} mb={2} p={2} h={70} justifyContent="center" alignItems="center">
                    <Text as="h1" color="#fff" fontWeight="bold" textAlign="center">PROFILLING ANALITIK COMPLETE</Text>
                  </Flex>
                  <Flex position="relative" flexDirection="column" cursor="pointer" onClick={() => window.location.assign('/profiling#4')} borderBottomRadius={10} py={2} px={3} backgroundColor="#059212" h={280}>
                    <Text color="#fff" >Profiling Analitik Complete ini memiliki hasil seperti pada Profiling Analitik Standard yang meliputi: Personalitas atau Kepribadian, Potensi Diri Kekuatan dan Kelemahan Karakter Kerja dan Gaya Kerja, Karier yang cocok Kesejahteraan Keuangan Path To Growth</Text>
                    <Text position="absolute" bottom={5} right={6} color="#fff" textAlign="end">...Read more</Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Box>
          <Box width="100%">
            <Text as="h1" fontWeight="bold" fontSize="lg" mb={3}>MATCHING SERVICE</Text>
            <Flex direction="row" mt={5} overflowX="hidden" width="100%">
              <Flex container gap={5} width="100%" flexWrap="nowrap" overflow="auto" style={{ scrollbarWidth: 'none' }}>
                <Flex direction="column" minWidth={{ sm: '100%', md: 270 }} maxWidth={{ sm: '100%', md: 270 }}>
                  <img src={MatchingPartnership} alt="profile" className={styles.img} />
                  <Flex backgroundColor="#7A611D" borderTopRadius={10} mb={2} p={2} h={70} justifyContent="center" alignItems="center">
                    <Text as="h1" color="#fff" fontWeight="bold" textAlign="center">PARTNERSHIP MATCHING</Text>
                  </Flex>
                  <Flex position="relative" flexDirection="column" cursor="pointer" onClick={() => window.location.assign('/matching-service#0')} borderBottomRadius={10} py={2} px={3} backgroundColor="#FBE7A3" h={235}>
                    <Text color="#000" >Kecocokan Rekan Kerja: Dalam lingkungan kerja, Persona dapat membantu mengidentifikasi tingkat kesesuaian dan kompatibilitas antara rekan kerja</Text>
                    <Text position="absolute" bottom={5} right={6} color="#000" textAlign="end">...Read more</Text>
                  </Flex>
                </Flex>
                <Flex direction="column" minWidth={{ sm: '100%', md: 270 }} maxWidth={{ sm: '100%', md: 270 }}>
                  <img src={MatchingRomance} alt="profile" className={styles.img} />
                  <Flex backgroundColor="#A225B5" borderTopRadius={10} mb={2} p={2} h={70} justifyContent="center" alignItems="center">
                    <Text as="h1" color="#fff" fontWeight="bold" textAlign="center">ROMANCE MATCHING</Text>
                  </Flex>
                  <Flex position="relative" flexDirection="column" cursor="pointer" onClick={() => window.location.assign('/matching-service#1')} borderBottomRadius={10} py={2} px={3} backgroundColor="#F7D0FC" h={235}>
                    <Text color="#000" >Metode profiling Persona Talenta ini memberikan wawasan tentang dinamika hubungan cinta dan sosial melalui analisis tanggal dan waktu lahir Anda</Text>
                    <Text position="absolute" bottom={5} right={6} color="#000" textAlign="end">...Read more</Text>
                  </Flex>
                </Flex>
                <Flex direction="column" minWidth={{ sm: '100%', md: 270 }} maxWidth={{ sm: '100%', md: 270 }}>
                  <img src={MatchingFriendship} alt="profile" className={styles.img} />
                  <Flex backgroundColor="#3E8C88" borderTopRadius={10} mb={2} p={2} h={70} justifyContent="center" alignItems="center">
                    <Text as="h1" color="#fff" fontWeight="bold" textAlign="center">FRIENDSHIP MATCHING</Text>
                  </Flex>
                  <Flex position="relative" flexDirection="column" cursor="pointer" onClick={() => window.location.assign('/matching-service#2')} borderBottomRadius={10} py={2} px={3} backgroundColor="#54B9B6" h={235}>
                    <Text color="#000" >Profiling yang kami lakukan secara komputasi otomatis ini dengan output hasil sebagai berikut</Text>
                    <Text position="absolute" bottom={5} right={6} color="#000" textAlign="end">...Read more</Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Box>
          <Box width="100%">
            <Text as="h1" fontWeight="bold" fontSize="lg" mb={3}>PERSONA SERVICE</Text>
            <Flex direction="row" mt={5} overflowX="hidden" width="100%">
              <Flex container gap={5} width="100%" flexWrap="nowrap" overflow="auto" style={{ scrollbarWidth: 'none' }}>
                <Flex direction="column" minWidth={{ sm: '100%', md: 270 }} maxWidth={{ sm: '100%', md: 270 }}>
                  <img src={PersonaGeneral} alt="profile" className={styles.img} />
                  <Flex backgroundColor="#253761" borderTopRadius={10} mb={2} p={2} h={70} justifyContent="center" alignItems="center">
                    <Text as="h1" color="#fff" fontWeight="bold" textAlign="center">GENERAL PERSONA</Text>
                  </Flex>
                  <Flex position="relative" flexDirection="column" cursor="pointer" onClick={() => window.location.assign('/persona-service#0')} borderBottomRadius={10} py={2} px={3} backgroundColor="#DCE3F1" h={390}>
                    <Text color="#000" >Analisis kepribadian (Personality) dan sifat tersembunyi (Hidden Nature) membantu mengidentifikasi karakter positif dan negatif (Positive and Negative Character), serta sisi terang dan gelap dari individu tersebut (The Bright and The Dark). Selain itu, kekuatan dan kelemahan (Strength and Weakness) juga dievaluasi untuk memberikan pemahaman yang lebih dalam tentang potensi dan area perbaikan</Text>
                    <Text position="absolute" bottom={5} right={6} color="#000" textAlign="end">...Read more</Text>
                  </Flex>
                </Flex>
                <Flex direction="column" minWidth={{ sm: '100%', md: 270 }} maxWidth={{ sm: '100%', md: 270 }}>
                  <img src={PersonaDaily} alt="profile" className={styles.img} />
                  <Flex backgroundColor="#4273B1" borderTopRadius={10} mb={2} p={2} h={70} justifyContent="center" alignItems="center">
                    <Text as="h1" color="#fff" fontWeight="bold" textAlign="center">DAILY PERSONA</Text>
                  </Flex>
                  <Flex position="relative" flexDirection="column" cursor="pointer" onClick={() => window.location.assign('/persona-service#1')} borderBottomRadius={10} py={2} px={3} backgroundColor="#F9FBFD" h={390}>
                    <Text color="#000" >Layanan Daily Vibe Persona memberikan analisis harian yang mendetail untuk membantu individu memahami dan memanfaatkan situasi sehari-hari. Melalui analisis harian (Daily Analytic), pengguna dapat memperoleh gambaran tentang situasi hari ini (Situation of the Day), yang mencakup aspek-aspek penting dalam kehidupan sehari-hari.</Text>
                    <Text position="absolute" bottom={5} right={6} color="#000" textAlign="end">...Read more</Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Box>
        </Flex>
      </div>
      <Footer />
    </div>
  );
}

export default Service;